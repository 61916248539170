<template>
    <div>
        <div class="entrepot-title-all">
            <div class="entrepot-title">
                <div @click="warehouseClick(1)" :class="[pageType == 1?'entrepot-color':'']">分仓退货</div>
                <div @click="warehouseClick(2)" :class="[pageType == 2?'entrepot-color':'']">总仓退货</div>
                <div @click="warehouseClick(3)" :class="[pageType == 3?'entrepot-color':'']">总仓库退货查询</div>
                <div @click="warehouseClick(4)" :class="[pageType == 4?'entrepot-color':'']">分仓库退货查询</div>
            </div>
            <div>
                <div v-if="pageType == 1" class="all-title-right">
                    <el-form :inline="true" :model="limit" >
                        <el-form-item label="仓库选择">
                            <el-select v-model="limit.type" filterable @change="selectClick" placeholder="查询选项">
                                <el-option  v-for="(item,key) in sales_mao_list" :key="key"  :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-date-picker
                        v-model="Seletime"
                        clearable
                        type="daterange"
                        align="right"
                        unlink-panels
                        value-format="yyyy-MM-dd"
                        @blur="noblurClick"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form>
                    <el-button class="selectButton-class" @click="queryButtonClick">查询</el-button>
                </div>
                <div v-if="pageType == 2" class="all-title-sales">
                    <el-input ref="inputCode" v-show="scanStatus" v-model="code" class="code-class" :autofocus="true"  @change="GteCode"  placeholder="请扫码"></el-input>
                    <el-button class="selectButton-class" @click="importButtonClick" >导出</el-button>
                    <el-button v-show="!scanStatus" class="addButton-class" @click="startButtonClick" >启动扫码退货</el-button>
                    <el-button v-show="scanStatus" class="addButton-class" @click="colseButtonClick" >关闭扫码退货</el-button>
                </div>

                <div v-if="pageType == 3">
                    <el-button style="margin-right:20px" class="selectButton-class" @click="importButtonsClick" >导出</el-button>
                    <el-date-picker
                        v-model="seleDataTime"
                        clearable
                        type="daterange"
                        align="right"
                        unlink-panels
                        value-format="yyyy-MM-dd"
                        @blur="noblurTime"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </div>

                <div v-if="pageType == 4">
                    <el-form :inline="true"  >
                        <el-button style="margin-right:20px" class="selectButton-class" @click="fencimportButtonsClick" >导出</el-button>
                        <el-form-item label="仓库选择">
                            <el-select v-model="fencid" filterable @change="selectClick" placeholder="查询选项">
                                <el-option  v-for="(item,key) in sales_mao_list" :key="key"  :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-date-picker
                        v-model="fenseleDataTime"
                        clearable
                        type="daterange"
                        align="right"
                        unlink-panels
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions">
                        </el-date-picker>
                        <el-button style="margin-right:20px" class="selectButton-class" @click="fencList" >查询</el-button>
                    </el-form>
                </div>
            </div>
            
        </div>
        

        <div v-if="pageType == 1">
            <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-background="#fff"
            :data="tableData"
            border
            stripe
            style="width: 100%">
                <el-table-column
                    prop="bar_code"
                    align="center"
                    label="条形码">
                </el-table-column>
                <el-table-column
                align="center"
                    label="图片">
                    <template scope="scope">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="scope.row.goods_url | replaceUrl"
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column
                    width="120"
                    label="二维码">
                    <template slot-scope="scope">
                        <vue-qr v-if="scope.row.qr_code"  :text="scope.row.qr_code" :size="100"></vue-qr>
                        <div v-else>暂无数据</div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="goods_type"
                    align="center"
                    width="120"
                    label="型号">
                </el-table-column>

                <el-table-column
                    prop="goods_brand"
                    align="center"
                    width="80"
                    label="品牌">
                </el-table-column>

                <el-table-column
                    prop="goods_norms"
                    align="center"
                    width="80"
                    label="规格">
                </el-table-column>

                <el-table-column
                    prop="goods_class"
                    align="center"
                    width="80"
                    label="类型">
                </el-table-column>

                <el-table-column
                    prop="goods_color"
                    align="center"
                    width="80"
                    label="颜色">
                </el-table-column>
                <el-table-column
                    align="center"
                    width="120"
                    label="详情图">
                    <template scope="scope">
                      <el-image :src="scope.row.goods_detail | replaceUrl"></el-image>
                    </template>
                </el-table-column>
                 <el-table-column
                    prop="reward"
                    align="center"
                    width="80"
                    label="奖金">
                </el-table-column>
                 <el-table-column
                    prop="goods_other"
                    align="center"
                    width="80"
                    label="附件">
                </el-table-column>
                <el-table-column
                    prop="price"
                    align="center"
                    label="单价">
                </el-table-column>
                <el-table-column
                    prop="reason"
                    align="center"
                    label="退货原因">
                </el-table-column>
                <el-table-column
                    prop="price"
                    align="center"
                    label="状态">
                    <template scope="scope">
                        <el-tag v-if="scope.row.bgstatus == 0">待审核</el-tag>
                        <el-tag v-if="scope.row.bgstatus == 1" type="success">已同意</el-tag>
                        <el-tag v-if="scope.row.bgstatus == 2" type="danger">已拒绝</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.bgstatus == 0" >
                            <el-button style="color: #8AC025;" type="text" @click="consentClick(1,scope.row)" size="small">同意退货</el-button>
                            <el-button style="color: red;" type="text" @click="consentClick(2,scope.row)" size="small">不同意退货</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
            class="table-limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageArray"
            :page-size= this.limit.page_size
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>

        </div>

        <div v-if="pageType == 2">
            <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-background="#fff"
            :data="tableDataAll"
            border
            stripe
            style="width: 100%">
                <el-table-column
                    prop="bar_code"
                    align="center"
                    label="条形码">
                </el-table-column>
                <el-table-column
                    label="图片">
                    <template scope="scope">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="scope.row.goods_url | replaceUrl "
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    width="100"
                    label="二维码">
                    <template slot-scope="scope">
                        <vue-qr v-if="scope.row.qr_code" :margin="10"  :text="scope.row.qr_code" :size="80"></vue-qr>
                        <div v-else>暂无数据</div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="goods_type"
                    align="center"
                    label="型号">
                </el-table-column>

                <el-table-column
                    prop="goods_brand"
                    align="center"
                    label="品牌">
                </el-table-column>

                <el-table-column
                    prop="goods_norms"
                    align="center"
                    label="规格">
                </el-table-column>

                <el-table-column
                    prop="goods_class"
                    align="center"
                    label="类型">
                </el-table-column>

                <el-table-column
                    prop="goods_color"
                    align="center"
                    label="颜色">
                </el-table-column>
                <el-table-column
                    prop="goods_detail"
                    align="center"
                    label="详情图">
                </el-table-column>
                 <el-table-column
                    prop="reward"
                    align="center"
                    label="奖金">
                </el-table-column>
                 <el-table-column
                    prop="goods_other"
                    align="center"
                    width="70"
                    label="附件">
                </el-table-column>
                 <el-table-column
                    prop="price"
                    align="center"
                    label="单价">
                </el-table-column>
                <!-- <el-table-column
                    label="单价">
                    <template slot-scope="scope">
                        <el-button type="text" @click="consentClick(1,scope.row)" size="small">同意退货</el-button>
                        <el-button type="text" @click="consentClick(2,scope.row)" size="small">不同意退货</el-button>
                    </template>
                </el-table-column> -->
            </el-table>

            <el-row class="bottom-all">
                <el-col :span="12" class="all-title-sales">
                    <el-col :span="3">退货原因</el-col>
                    <el-col :span="12">
                        <el-input placeholder="请填写退货原因" v-model="formData.remark"></el-input>
                    </el-col>
                </el-col>
                <el-col :span="12" style="text-align: right;">
                    <el-button @click="afirmClick" class="afirmButton-class">确认退货</el-button>
                </el-col>
            </el-row>

        </div>

        <div v-if="pageType == 3">
            <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-background="#fff"
            :data="tableDatatuihuo"
            border
            stripe
            style="width: 100%">
                <el-table-column
                    prop="create_time"
                    align="center"
                    label="总仓退货时间">
                    <template scope="scope">
                        {{ scope.row.create_time | timeFilter }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="bar_code"
                    align="center"
                    label="条形码">
                    <template slot-scope="scope">
                        {{scope.row.bar_code}}
                    </template>
                </el-table-column>
                <el-table-column
                    label="图片">
                    <template scope="scope">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="scope.row.goods_url | replaceUrl "
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    width="100"
                    label="二维码">
                    <template slot-scope="scope">
                        <vue-qr v-if="scope.row.qr_code" :margin="10"  :text="scope.row.qr_code" :size="80"></vue-qr>
                        <div v-else>暂无数据</div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="goods_type"
                    align="center"
                    label="型号">
                </el-table-column>

                <el-table-column
                    prop="goods_brand"
                    align="center"
                    label="品牌">
                </el-table-column>

                <el-table-column
                    prop="goods_norms"
                    align="center"
                    label="规格">
                </el-table-column>

                <el-table-column
                    prop="goods_class"
                    align="center"
                    label="类型">
                </el-table-column>

                <el-table-column
                    prop="goods_color"
                    align="center"
                    label="颜色">
                     <template slot-scope="scope">
                        {{scope.row.goods_color}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goods_detail"
                    align="center"
                    label="详情图">
                    <template scope="scope">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="scope.row.goods_detail | replaceUrl "
                        ></el-image>
                    </template>
                </el-table-column>
                 <el-table-column
                    prop="reward"
                    align="center"
                    label="奖金">
                </el-table-column>
                 <el-table-column
                    prop="goods_other"
                    align="center"
                    label="附件">
                </el-table-column>
                 <el-table-column
                    prop="price"
                    align="center"
                    label="单价">
                </el-table-column>
            </el-table>
        </div>

        <div v-if="pageType == 4">
            <el-table
                v-loading="loading"
                element-loading-text="拼命加载中"
                element-loading-background="#fff"
                :data="tableDatafentuihuo"
                border
                stripe
                style="width: 100%">
                    <el-table-column
                        label="图片">
                        <template scope="scope">
                            <el-image
                            style="width: 100px; height: 100px"
                            :src="scope.row.goods_url | replaceUrl "
                            ></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="详情">
                        <template scope="scope">
                            <el-image
                            style="width: 100px; height: 100px"
                            :src="scope.row.goods_detail | replaceUrl "
                            ></el-image>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="goods_type"
                        align="center"
                        label="型号">
                        <template scope="scope">
                            {{scope.row.goods_type}}
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="goods_brand"
                        align="center"
                        label="品牌">
                    </el-table-column>
                    <el-table-column
                        prop="goods_norms"
                        align="center"
                        label="规格">
                    </el-table-column>
                    <el-table-column
                        prop="goods_class"
                        align="center"
                        width="80"
                        label="类型">
                    </el-table-column>
                    <el-table-column
                        prop="goods_color"
                        align="center"
                        width="80"
                        label="颜色">
                        <template slot-scope="scope">
                            {{scope.row.goods_color}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="reward"
                        align="center"
                        label="奖金">
                    </el-table-column>
                    <el-table-column
                        prop="price"
                        align="center"
                        label="单价">
                        <template slot-scope="scope">
                            {{scope.row.price}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="reason"
                        align="center"
                        label="退货原因">
                        <template slot-scope="scope">
                            {{scope.row.reason}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="back_phone"
                        align="center"
                        label="联系方式">
                    </el-table-column>
                    <el-table-column
                        prop="back_name"
                        align="center"
                        label="退货仓库姓名">
                    </el-table-column>
                    <el-table-column
                        prop="warehouse_name"
                        align="center"
                        label="退货仓库地址">
                    </el-table-column>
                    <el-table-column
                        prop="price"
                        align="center"
                        label="状态">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.bgstatus == 0">待审核</el-tag>
                            <el-tag type="success" v-if="scope.row.bgstatus == 1">已同意</el-tag>
                            <el-tag type="warning" v-if="scope.row.bgstatus == 2">已拒绝</el-tag>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination
                class="table-limit"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="pageArray"
                :page-size= this.limit.page_size
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>

        </div>
        
    </div>
</template>

<script>
import { sales_list ,fenbackgoodsinfo,backgoodsinfo,backgoods,fenbackgoods,scancode,showbackgoods,fenbackgoodsshow} from '@/api/sales.js'
export default {
    name:'Entrepot',
    data(){
        return{
            pageArray:this.$store.state.pageArray,
            limit:{
                page:1,
                page_size:10, 
                create_time:'',
                warehouse_id:'',
            },
            pageType:1,
            loading:true,
            Seletime:[],
            sales_mao_list:[],
            tableData:[],
            total:0,
            currentPage:1,
            tableDataAll:[],
            tableDatatuihuo:[],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            seleDataTime:[],
            code:'',
            formData:{
                qr_code:'',
                remark:''
            },
            scanStatus:false,
            fenseleDataTime:[],
            tableDatafentuihuo:[],
            fencid:''
        }
    },
    created(){
        // 获取首页数据
        this.getlist()
        // 获取仓库列表
        this.get_changkuList()
        // 获取默认时间
        this.getMtime()
        
    },
    methods:{
        // 仓库管理列表
        getlist(){
            fenbackgoodsinfo(this.limit).then(res=>{
				let data = res.data.data
				this.total = res.data.total
				this.loading = false
				console.log(data)
				return
				this.tableData = data
               
            })
        },

        // 获取仓库列表
        get_changkuList(){
            sales_list({all:true}).then(res=>{
                this.sales_mao_list = res.data
            })
        },

        //确定退货
        afirmClick(){
            if(this.tableDataAll.length <= 0){
                this.$message({
                    type:'warning',
                    message:'提交数据不能为空'
                })
                return
            }
            let str = ''
            this.tableDataAll.forEach(element=>{
                if(str){
                    str = str + ',' + element.id
                }else{
                    str = element.id
                }
            })
            this.formData.id = str
            backgoods(this.formData).then(res=>{
                this.$message({
                    type:'success',
                    message:res.msg
                })
                this.tableDataAll = []
                this.formData.qr_code = ''
                this.formData.remark = ''
                return
            })

        },

        // 时间选择获取最近一个月
        getMtime(){
            let end = new Date();
            let nowend = this.formatDate(end);
            let start = new Date();
            start.setDate(1);
            start.setHours(0);
            start.setSeconds(0);
            start.setMinutes(0);
            start = start.getTime()
            let nowstart = this.formatDate(start);
            this.Seletime = [nowstart,nowend]
            this.seleDataTime = [nowstart,nowend]
            this.fenseleDataTime = [nowstart,nowend]
        },

        // 格式化日期
        formatDate(date) {
            var date = new Date(date);
            var YY = date.getFullYear() + '-';
            var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
            return YY + MM + DD ;
        },

        //失去焦点
        noblurClick(e){
            // this.fencList()
        },

        // 查询时间失去焦点
        noblurTime(){
            this.tuihuoList()
        },

        // 点击查询
        queryButtonClick(){
            let timeMerge = this.Seletime[0] + '~' + this.Seletime[1]
            this.limit.create_time = timeMerge
            this.loading = true
            this.getlist()
        },

        // 下拉选择
        selectClick(e){
            this.limit.warehouse_id = e
        },

        // 1同意退货  2拒绝退货
        consentClick(key,row){
            let msg = ''
            if(key == 1){
                msg = '确定同意退货吗？'
            }else{
                msg = '此操作将拒绝退货'
            }
            this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let data = {
                        status:key,
                        id:row.id
                    }
                    fenbackgoods(data).then(res=>{
                        if(res.code == 200){
                            // 确定删除
                            this.$message({
                                type: 'success',
                                message: res.msg
                            });
                            this.getlist()
                        }
                    })
                    
                }).catch(() => {         
            });
        },

        // 启动扫码入库
        startButtonClick(){
            this.scanStatus = true
            this.$message({
                type:'success',
                message:'开启成功，请不要点鼠标，开始扫码'
            })
            this.$nextTick(function () {
                this.$refs.inputCode.focus()
            });
        },
        noblurClicks(e){
            this.fencList()
        },
        // 输入框失焦
        blurInput(){
            this.colseButtonClick()
        },
        // 导出
        importButtonClick(){
            import('@/vendor/Export2Excel').then(excel => {
            const header = ['条形码','型号', '品牌', '规格','类别','颜色','奖金','附件','单价'] // 最后一行的表头数据
            const filterVal = ['bar_code','goods_type','goods_brand','goods_norms','goods_class','goods_color','reward','goods_other','price']
            const list = this.tableDataAll
            const data = this.formatJson(filterVal, list)
            // console.log(data)
            // return
            // const merges = ['A1:K1','A3:A4','B3:C3','D3:E3','F3:G3','H3:I3','J3:K3'] // 合并单元格的数据，如何合并参考上面图片的蓝色背景部分
            excel.export_json_to_excel({
                // multiHeader,
                header,
                // merges,
                data,
                filename:'总仓退货清单',
            })
            })
        },

        // 导出退货查询的数据
        importButtonsClick(){
            import('@/vendor/Export2Excel').then(excel => {
                const header = ['条形码','型号', '品牌', '规格','类别','颜色','奖金','附件','单价'] // 最后一行的表头数据
                const filterVal = ['bar_code','goods_type','goods_brand','goods_norms','goods_class','goods_color','reward','goods_other','price']
                const list = this.tableDatatuihuo
                const data = this.formatJson(filterVal, list)
                // console.log(data)
                // return
                // const merges = ['A1:K1','A3:A4','B3:C3','D3:E3','F3:G3','H3:I3','J3:K3'] // 合并单元格的数据，如何合并参考上面图片的蓝色背景部分
                excel.export_json_to_excel({
                    // multiHeader,
                    header,
                    // merges,
                    data,
                    filename:'总仓库退货',
                })
            })
        },

        // 分仓退货查询导出
        fencimportButtonsClick(){
            import('@/vendor/Export2Excel').then(excel => {
                const header = ['型号', '品牌', '规格','类别','颜色','奖金','附件','单价','退货原因','联系方式','退货仓库姓名','退货仓库地址'] // 最后一行的表头数据
                const filterVal = ['goods_type','goods_brand','goods_norms','goods_class','goods_color','reward','goods_other','price','reason','back_phone','back_name','warehouse_name']
                const list = this.tableDatafentuihuo
                const data = this.formatJson(filterVal, list)
                excel.export_json_to_excel({
                    header,
                    data,
                    filename:'总仓库退货',
                })
            })
        },

        formatJson(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => {
                return v[j]
            }))
        },

        //扫二维码上传商品
        GteCode(){
            console.log(this.code)
            backgoodsinfo({qr_code:this.code}).then(res=>{
                this.code = ''
                if(res.code == 200){
                    this.$message({
                        type:'success',
                        message:res.msg
                    })
                    if(res.data.id){
                        let data = res.data
                        console.log(res.data)
                        this.tableDataAll.push(res.data)
                    }
                }
            })
        },

        // 关闭扫码入库
        colseButtonClick(){
            this.$message({
                type:'warning',
                message:'关闭成功'
            })
            this.code=''
            this.scanStatus = false

        },

        //仓库  库存切换
        warehouseClick(key){
			console.log(key)
            if(this.pageType == key){
                return
            }
            this.limit.page = 1
            this.total = 0
            if(key == 1){
                this.loading = true
                this.getlist()
            }
            if(key == 3){
                // 退货查询
                this.loading = true
                this.tuihuoList()
            }
            if(key == 4){
                // 分仓库退货查询
                this.loading = true
                this.fencList()
            }
            this.pageType = key
        },

        // 总仓库退货查询
        tuihuoList(){
            let data = this.seleDataTime[0] +'~' + this.seleDataTime[1]
            showbackgoods({create_time:data}).then(res=>{
                console.log(res)
                console.log(res.data)
                let data = res.data
                this.tableDatatuihuo = data
                this.loading = false
            })
        },

        // 分仓库退货查询
        fencList(){
            let data = this.fenseleDataTime[0] +'~' + this.fenseleDataTime[1]
            let datas = {
                create_time:data,
                warehouse_id:this.fencid,
                page: this.limit.page,
                page_size: this.limit.page_size
            }
            fenbackgoodsshow(datas).then(res=>{
                console.log(res)
                let data = res.data.data
                this.tableDatafentuihuo = data
                this.total = res.data.total
                this.loading = false
            })
            
        },
        
        /**
         * 点击页码触发
         */
        handleSizeChange(val) {
            this.limit.page_size = val
            this.loading = true
            if(this.pageType == 1){
                this.getlist()
            }
            if(this.pageType == 4){
                this.fencList()
            }
            
        },
        handleCurrentChange(val) {
            this.limit.page = val
            this.loading = true
            if(this.pageType == 1){
                this.getlist()
            }
            if(this.pageType == 4){
                this.fencList()
            }
        }

    }
}
</script>

<style scoped>
.beizhu{
    color: #FFAF24;

}
.entrepot-title-all{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.entrepot-title{
    width: 720px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    line-height: 90px;

}
.entrepot-color{
    color: #428CFF;
}
.addButton-class{
    background: linear-gradient(90deg, #FF861B, #FFB527);
    box-shadow: 0px 5px 30px 0px rgba(255, 142, 66, 0.4);
    border-radius: 6px;
    border: none;
    color: #fff;

}
.input-all{
    width: 90%;
}
.selectButton-class{
    background: linear-gradient(90deg, #0266FF, #16ACFF);
    box-shadow: 0px 5px 30px 0px rgba(38, 122, 251, 0.4);
    border-radius: 6px;
    border: none;
    color: #fff;
    margin-left: 20px;
}
.all-title-right{
    display: flex;   
    align-items: center;
}
.el-form-item{
    margin-bottom: 0 !important;
}
.all-title-sales{
    display: flex;
    align-items: center;
}
.code-class{
    width: 400px;
    margin-right: 40px;
}
.bottom-all{
    margin-top: 50px;
}
.afirmButton-class{
    background: linear-gradient(90deg, #FF774E, #FF4C16);
    box-shadow: 0px 5px 30px 0px rgba(255, 77, 23, 0.4);
    border-radius: 6px;
    border: none;
    color: #fff;
}
</style>